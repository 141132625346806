import { ChainId, Token } from '@pancakeswap/sdk'
import { ChainLogo } from 'components/Logo/ChainLogo'



const getTokenLogoURL = (token?: Token) => {
  if (token && token.address) {
    return `/tokens/${token.chainId}/${token.address}.png`
  }
  return null
}

export default getTokenLogoURL
